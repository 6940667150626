var config = require( './01-config.js' );

var slick_min = require( './02-slick.min.js' );

// lightbox用のjs
// var core = require( './05-core.js' );
// var touch = require( './06-touch.js' );
// var transition = require( './07-transition.js' );
// var lightbox = require( './08-lightbox.js' );

var main = require( './09-main.js' );
