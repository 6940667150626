//コピーライト
var TodayData = new Date();
var thisYear = TodayData.getFullYear();
window.onload = function() {
	document.getElementById('js_copyYear').innerHTML = '©' + ' ' + thisYear + ' CareAvail Inc.';
};


// ヘッダー動作（下にスクロールで隠れて、上にスクロールで再表示する）
var startPos = 0;
$(window).scroll(function(){
	var currentPos = $(this).scrollTop();
	var w = $(window).width();
	if (w > 768) {
		if (currentPos > startPos) {
			if($(window).scrollTop() >= 100) {
				$("#header").css("top", "-" + 110 + "px");
			}
		} else {
				$("#header").css("top", 0 + "px");
				$("#header").css("transition", .3 + "s");
		}
		startPos = currentPos;
	}
});


// 【PC版 トップページ】スクロールするまで専用グロナビ（途中から.js_topMenuPcを削除）
$(function(){
	 $(window).scroll(function(){
			var scr = $(window).scrollTop();
			if (scr < 580) {
				$("body.home").addClass('js_topMenuPc');
			} else {
				$("body.home").removeClass('js_topMenuPc');
				$("body.page").removeClass('js_topMenuPc');
				$("body.archive").removeClass('js_topMenuPc');
				$("body.single").removeClass('js_topMenuPc');
			}
	 })
})


// ハンバーガーメニュー
$(function(){
	$('#js_GNav__menuBtn').click(function(){
		var scroll = 0;
		scroll = $(window).scrollTop();
		// メニューをスクロールしても、ページそのものはスクロールされないように
		$("html").toggleClass('-fixed');
		$("#js_GNav__menuBtn").toggleClass('js_GNav__menuCloseBtn');
		$('html').css({
			'top': -scroll
		});
		$("#js_GNav__menuBtn").toggleClass('-open');
		// フェードイン／フェードアウト
		$(".GNav__menu").fadeToggle(400);
	});
	// メニューのリンクから別のページに飛んだら「-fixed」クラスを削除する
	$("html").removeClass('-fixed');
});


// 「仕事を探す（詳細ページ）」の「この求人に応募する」ボタン
$(document).ready(function(){

		// スクロールが始まったら
		$(window).on("scroll", function() {

				// ページの高さ
				var scrollHeight = $(document).height();

				//  表示画面の高さ ＋ ページに対するスクロール量
				var scrollPosition = $(window).height() + $(window).scrollTop();

				//  フッターの高さ
				var footHeight = $("#footer").innerHeight();

				//  画面（viewport）の横幅
				var w = $(window).width();

				// フッターの位置（ページの高さ − 表示画面の高さ ＋ ページに対する現在のスクロール量）までスクロールしてきたとき
				if ( scrollHeight - scrollPosition  <= footHeight ) {

				// 現在の下から位置が、フッターの高さの位置にはいったら
				// ".gotop"のpositionをabsoluteに変更し、フッターの高さの位置にする
						$("#Job__btnEntryContainer").css({
								"position": "absolute",
						});
						if (w > 768) {
							$("#Job__btnEntryContainer").css({
									"bottom": footHeight + 120 + "px",
							});
						} else {
							$("#Job__btnEntryContainer").css({
									"bottom": footHeight + 80 + "px",
							});
						}
				// それ以外の場合は元のcssスタイルを指定
				} else {
						$("#Job__btnEntryContainer").css({
								"position": "fixed",
						});
						if (w > 768) {
							$("#Job__btnEntryContainer").css({
									"bottom": "40px",
							});
						} else {
							$("#Job__btnEntryContainer").css({
									"bottom": "20px",
							});
						}
				}
		});
});


// 「セミナー・研修（詳細ページ）」の「参加を申し込む」ボタン
$(document).ready(function(){

		// スクロールが始まったら
		$(window).on("scroll", function() {

				// ページの高さ
				var scrollHeight = $(document).height();

				//  表示画面の高さ ＋ ページに対するスクロール量
				var scrollPosition = $(window).height() + $(window).scrollTop();

				//  フッターの高さ
				var footHeight = $("#footer").innerHeight();

				//  画面（viewport）の横幅
				var w = $(window).width();

				// フッターの位置（ページの高さ − 表示画面の高さ ＋ ページに対する現在のスクロール量）までスクロールしてきたとき
				if ( scrollHeight - scrollPosition  <= footHeight ) {

				// 現在の下から位置が、フッターの高さの位置にはいったら
				// ".gotop"のpositionをabsoluteに変更し、フッターの高さの位置にする
						$("#Seminar__btnEntryContainer").css({
								"position": "absolute",
						});
						if (w > 768) {
							$("#Seminar__btnEntryContainer").css({
									"bottom": footHeight + 180 + "px",
							});
						} else {
							$("#Seminar__btnEntryContainer").css({
									"bottom": footHeight + 80 + "px",
							});
						}
				// それ以外の場合は元のcssスタイルを指定
				} else {
						$("#Seminar__btnEntryContainer").css({
								"position": "fixed",
						});
						if (w > 768) {
							$("#Seminar__btnEntryContainer").css({
									"bottom": "40px",
							});
						} else {
							$("#Seminar__btnEntryContainer").css({
									"bottom": "20px",
							});
						}
				}
		});
});


// フッターの固定ボタン
$(document).ready(function(){

		// ページがスクロールされるまでは隠しておく
		$("#fixedFt").hide();

		// スクロールが始まったら
		$(window).on("scroll", function() {
				// 100pxスクロールしたら表示
				if ($(this).scrollTop() > 100) {
						$('#fixedFt').slideDown("fast");
				// ページの一番上では非表示
				} else {
						$('#fixedFt').slideUp("fast");
				}

				// ページの高さ
				var scrollHeight = $(document).height();

				//  表示画面の高さ ＋ ページに対するスクロール量
				var scrollPosition = $(window).height() + $(window).scrollTop();

				//  フッターの高さ
				var footHeight = $("#footer").innerHeight();

				// フッターの位置（ページの高さ − 表示画面の高さ ＋ ページに対する現在のスクロール量）までスクロールしてきたとき
				if ( scrollHeight - scrollPosition  <= footHeight ) {

				// 現在の下から位置が、フッターの高さの位置にはいったら
				// ".gotop"のpositionをabsoluteに変更し、フッターの高さの位置にする
						$("#fixedFt").css({
								"position": "absolute",
								"bottom": footHeight,
						});
				} else {
				// それ以外の場合は元のcssスタイルを指定
						$("#fixedFt").css({
								"position": "fixed",
								"bottom": "-1px",
						});
				}
		});
});


// タブ
$(function() {
		$(".js_tab .tabTitle").click(function() {
				var num = $(".js_tab .tabTitle").index(this);
				$(".tabContent").removeClass('active');
				$(".tabContent").eq(num).addClass('active');
				$(".js_tab .tabTitle").removeClass('active');
				$(this).addClass('active')
		});
});


// スムーズスクロール
$(function(){
	 $('a[href^="#"]').click(function() {
			var speed = 300;
			var href= $(this).attr("href");
			var target = $(href == "#" || href == "" ? 'html' : href);
			var position = target.offset().top;
			$('html,body').animate({scrollTop:position}, speed, 'swing');
			return false;
	 });
});


// ホバーメニュー
$(function(){
	$('.pullDownPc').hover(function() {
		var w = $(window).width();
		if (w > 768) {
			$(this).toggleClass('active');
			$(this).children('.pullDownContentPc').stop().slideToggle();
		}
	});
});


// トグル
$(function(){
	$('.js_toggleBtn').click(function() {
			$(this).toggleClass('-active');
			$(this).next('.js_togglContents').slideToggle();
	});
});


// lightBox
// $(function () {
// 	$(".lb").lightbox();
// });
